/* MacOS scrollbar fix */

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 11px;
}

::-webkit-scrollbar:horizontal {
  height: 11px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border-color: var(--main-bg);
  background-color: var(--button-color);
}

::-webkit-scrollbar-track {
  background-color: var(--bar);
}

.MuiGrid-spacing-xs-1 {
  margin-bottom: 0px !important;
}

.pickr {
  display: inline-block;
  height: 100% !important;
  width: 100% !important;
  .pcr-button {
    height: 100% !important;
    width: 100% !important;
  }
}

@keyframes color-me-in {
  0% {
    background: initial;
  }
  20% {
    background: initial;
  }
  70% {
    background: rgb(254, 255, 182);
  }
  100% {
    background: initial;
  }
}

.highlight {
  animation: color-me-in 2s;
}

.ql-editor {
  height: auto;
}

.ql-size-large {
  font-size: 20px !important;
}
.ql-size-small {
  font-size: 10px !important;
}
.ql-size-huge {
  font-size: 30px !important;
}
